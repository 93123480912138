import React from 'react';
// import './App.css';

class Footer extends React.Component {
  render() {
    return (
      <footer role="contentinfo">Whitney Wallace 2019</footer>
    );
  }
}

export default Footer;